import React, { useState, useEffect } from "react";
import Logo from "../assets/logo.svg";

import Malt from "../assets/icons/malt.webp";
import MaltHover from "../assets/icons/maltHover.webp";
import Linkedin from "../assets/icons/linkedin.webp";
import LinkedinHover from "../assets/icons/linkedinHover.webp";
import Dribbble from "../assets/icons/dribbble.webp";
import DribbbleHover from "../assets/icons/dribbbleHover.webp";
const Navbar = ({ aboutRef, workRef, contactRef }) => {
    const [maltHover, setMaltHover] = useState(false);
    const [linkedinHover, setLinkedinHover] = useState(false);
    const [dribbbleHover, setDribbbleHover] = useState(false);
    const [isMobile, setIsMobile] = useState();

    useEffect(() => {
        const media = window.matchMedia("(max-width: 650px)");
        const listener = () => setIsMobile(media.matches);
        listener();
        window.addEventListener("resize", listener);
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const goToAbout = (e) => {
        e.preventDefault();
        aboutRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };

    const goToWork = (e) => {
        e.preventDefault();
        workRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };

    const goToContact = (e) => {
        e.preventDefault();
        contactRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };

    return (
        <div className="navbar">
            <img
                className="logo"
                src={Logo}
                alt="O&V"
                onClick={() => scrollToTop()}
            />
            {isMobile === false ? (
                <div className="menu">
                    <button onClick={goToAbout}>À propos de nous</button>
                    <button onClick={goToWork}>Nos créations</button>
                    <button onClick={goToContact}>Nous contacter</button>
                </div>
            ) : (
                <></>
            )}
            <div className="nav-logos">
                <a
                    target="blank"
                    href="https://www.malt.fr/profile/opheliebaribaud"
                >
                    <img
                        onMouseOver={() => setMaltHover(true)}
                        onMouseOut={() => setMaltHover(false)}
                        src={maltHover === true ? MaltHover : Malt}
                        alt="Logo de Malt"
                    />
                </a>
                <a
                    target="blank"
                    href="https://www.linkedin.com/company/ophelie-valentine/"
                >
                    <img
                        onMouseOver={() => setLinkedinHover(true)}
                        onMouseOut={() => setLinkedinHover(false)}
                        src={linkedinHover === true ? LinkedinHover : Linkedin}
                        alt="Logo de LinkedIn"
                    />
                </a>
                <a
                    target="blank"
                    href="https://dribbble.com/Ophelievalentine"
                >
                    <img
                        onMouseOver={() => setDribbbleHover(true)}
                        onMouseOut={() => setDribbbleHover(false)}
                        src={dribbbleHover === true ? DribbbleHover : Dribbble}
                        alt="Logo de Dribbble"
                    />
                </a>
            </div>
        </div>
    );
};

export default Navbar;
