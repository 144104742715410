import { React, useState, useEffect } from "react";
import HomeLogo from "../assets/homeLogo.svg";
import Pics from "../assets/pics.webp";
import PicsMobile from "../assets/pics_mobile.webp";

const Home = ({ contactRef }) => {
    const [isMobile, setIsMobile] = useState();

    useEffect(() => {
        const media = window.matchMedia("(max-width: 900px)");
        const listener = () => setIsMobile(media.matches);
        listener();
        window.addEventListener("resize", listener);
    });

    const goToContact = (e) => {
        e.preventDefault();
        contactRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };

    console.log(contactRef);

    return (
        <div className="home section">
            {isMobile === true ? (
                <div className="pics">
                    <img
                        src={PicsMobile}
                        alt="Des polaroids de Ophélie et Valentine"
                    />
                </div>
            ) : (
                <></>
            )}
            <div className="infos">
                <img src={HomeLogo} alt="O&V" />
                <p className="description">
                    Nous sommes une équipe de deux UX/UI
                    designers et développeuses web et mobile free-lance basées à Paris,
                    passionnées et prêtes à tout pour vous créer le meilleur
                    site ou la meilleure application.
                </p>
                <div className="div-button" id="home-button" onClick={goToContact}>
                    <button className="button">
                        <p>Nous contacter</p>
                    </button>
                </div>
            </div>
            {isMobile === false ? (
                <div className="pics">
                    <img
                        src={Pics}
                        alt="Des polaroids de Ophélie et Valentine"
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Home;
