import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Work from "./components/Work"
import Contact from "./components/Contact";
import { useRef } from "react";

function App() {
    const aboutRef = useRef(null);
    const workRef = useRef(null);
    const contactRef = useRef(null);

    return (
        <div className="page">
            <Navbar aboutRef={aboutRef} workRef={workRef} contactRef={contactRef} />
            <div className="page-inside">
                <Home contactRef={contactRef} />
                <About ref={aboutRef}/>
                <Work ref={workRef} />
                <Contact ref={contactRef} />
            </div>
        </div>
    );
}

export default App;
