import React from "react";

export class WorkComponent extends React.Component {
    render() {
        return (
            <div className="works">
                <div className="work-image">
                    <img src={this.props.img} alt={this.props.alt} />
                </div>
                <div className="works-infos">
                    <h3>{this.props.title}</h3>
                    <div className="tags">
                        {this.props.tags.map((tag) => (
                            <div className="tag">
                                <p>{tag}</p>
                            </div>
                        ))}
                    </div>
                    {this.props.description}
                    <div className="icons-used">
                        {this.props.icons.map((icon) => (
                            <div>{icon}</div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export class WorkTwoComponent extends React.Component {
    render() {
        return (
            <div className="works works-backwards">
                <div className="works-infos works-infos-backwards">
                    <h3>{this.props.title}</h3>
                    {this.props.tags.map((tag) => (
                        <div className="tag">
                            <p>{tag}</p>
                        </div>
                    ))}
                    {this.props.description}
                    <div className="icons-used">
                        {this.props.icons.map((icon) => (
                            <div>{icon}</div>
                        ))}
                    </div>
                    <p className="review">{this.props.review}</p>
                </div>
                <div className="work-image">
                    <img src={this.props.img} alt={this.props.alt} />
                </div>
            </div>
        );
    }
}
