import Photoshop from "../assets/skills/ps.svg";
import Flutter from "../assets/skills/flutter.svg";
import Mongo from "../assets/skills/MDB.svg";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFigma,
    faNodeJs,
    faJs,
    faVuejs,
    faReact,
    faWordpress,
    faPython,
    faPhp,
    faHtml5,
    faCss3,
} from "@fortawesome/free-brands-svg-icons";
import { Title } from "./utils/title";
import { forwardRef } from "react";

const About = forwardRef((props, ref) => {
    return (
        <div className="about" ref={ref}>
            <div className="section">
                <Fade>
                    <Title title="À propos de nous" />
                </Fade>
                <Fade>
                    <div className="about-description">
                        <div className="about-description-content">
                            <p>
                                Hello ! Nous c’est Ophélie et Valentine, et on
                                bosse depuis maintenant plus d’un an ensemble
                                chez{" "}
                                <a
                                    href="https://bibisorties.com/"
                                    target="blank"
                                >
                                    bibisorties.com
                                </a>
                                . Avant ça on est passées par{" "}
                                <span className="bold">Epitech</span> et{" "}
                                <span className="bold">42</span> mais, entre
                                nous, on a surtout appris sur le terrain.
                                <br />
                                <br />
                            </p>
                        </div>
                        {/* <div className="about-description-catchphrase">
                        Aujourd’hui, nous sommes heureuses de continuer à
                        travailler sur des projets toujours différents, par
                        passion et amour du challenge.
                    </div> */}
                        <div className="about-description-content">
                            <p>
                                On s’intéresse à peu près à tout. Non, vraiment
                                : le développement front, le développement back,
                                l’UX/UI, le graphisme…{" "}
                                <span className="bold">
                                    Bref, tout ce qui permet d’imaginer des
                                    projets de site ou d’application de A à Z
                                </span>
                                .
                                <br />
                                <br />
                                Évidemment, chacune a ses domaines de
                                prédilection, ce qui fait de nous{" "}
                                <span className="bold">
                                    une équipe hyper complémentaire
                                </span>
                                . Et on vous rassure, ce n’est pas parce qu’on
                                est deux qu’on coûte deux fois plus cher. On a
                                juste{" "}
                                <span className="bold">
                                    deux fois plus d’idées
                                </span>
                                . ✨
                            </p>
                        </div>
                        <div className="skills">
                            <div className="code">
                                <h4>Code</h4>
                                <div className="code-icons">
                                    <div className="code-icons-line">
                                        <FontAwesomeIcon
                                            icon={faHtml5}
                                            title="HTML 5"
                                        />
                                        <FontAwesomeIcon
                                            icon={faCss3}
                                            title="CSS 3"
                                        />
                                        <img
                                            src={Flutter}
                                            alt="Flutter icon"
                                            title="Flutter"
                                        />
                                        <FontAwesomeIcon
                                            icon={faJs}
                                            title="JavaScript"
                                        />
                                        <FontAwesomeIcon
                                            icon={faReact}
                                            title="React Js"
                                        />
                                        <FontAwesomeIcon
                                            icon={faVuejs}
                                            title="Vue Js"
                                        />
                                    </div>
                                    <div className="code-icons-second-line">
                                        <FontAwesomeIcon
                                            icon={faNodeJs}
                                            title="Node Js"
                                        />
                                        <FontAwesomeIcon
                                            icon={faPhp}
                                            title="PHP"
                                        />
                                        <FontAwesomeIcon
                                            icon={faPython}
                                            title="Python"
                                        />
                                        <FontAwesomeIcon
                                            icon={faWordpress}
                                            title="Wordpress"
                                        />
                                        <img
                                            src={Mongo}
                                            alt="MongoDB icon"
                                            title="MongoDB"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="design">
                                <h4>Design</h4>
                                <div className="design-icons">
                                    <FontAwesomeIcon
                                        icon={faFigma}
                                        title="Figma"
                                    />
                                    <img
                                        src={Photoshop}
                                        alt="Photoshop icon"
                                        title="Photoshop"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="services">
                            <h4>Nos services</h4>
                            <div className="services-list">
                                <div className="services-item">
                                    <div className="highlight-services">
                                        <div className="highlight-gradient-services"></div>
                                    </div>
                                    <p>
                                        <span className="bold">
                                            Design UI/UX
                                        </span>
                                        , maquettes sur Figma
                                    </p>
                                </div>
                                <div className="services-item">
                                    <div className="highlight-services">
                                        <div className="highlight-gradient-services"></div>
                                    </div>
                                    <p>
                                        Développement de tous types de{" "}
                                        <span className="bold">sites web</span>
                                    </p>
                                </div>
                                <div className="services-item">
                                    <div className="highlight-services">
                                        <div className="highlight-gradient-services"></div>
                                    </div>
                                    <p>
                                        Développement d’
                                        <span className="bold">
                                            applications mobile
                                        </span>
                                    </p>
                                </div>
                                <div className="services-item">
                                    <div className="highlight-services">
                                        <div className="highlight-gradient-services"></div>
                                    </div>
                                    <p>
                                        <span className="bold">
                                            Mise en ligne
                                        </span>{" "}
                                        de sites web
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
});

export default About;
