import { Title } from "./utils/title";
import { forwardRef } from "react";
import ArrowUp from "../assets/icons/Arrow_Up.svg";
import Fade from "react-reveal/Fade";

const Contact = forwardRef((props, ref) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="contact" ref={ref}>
            <div className="section">
                <Fade>
                    <Title title="Contactez-nous !" />
                </Fade>
                <Fade>
                <div className="contact-us">
                    <p>
                        N'hésitez pas à nous contacter par mail pour nous parler
                        de votre projet, on vous répondra au plus vite ☺️
                    </p>
                    <div className="last-line">
                        <h3>contact@ophelievalentine.fr</h3>
                        <div
                            className="backtotop"
                            onClick={() => scrollToTop()}
                        >
                            <img src={ArrowUp} alt="Revenir en haut" />
                        </div>
                    </div>
                </div>
                </Fade>
                <p className="credits">
                    2022 / Site web designé et développé par Ophélie et
                    Valentine.
                </p>
            </div>
        </div>
    );
});

export default Contact;
