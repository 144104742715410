import { Title } from "./utils/title";
import { WorkComponent } from "./utils/work";
import { WorkTwoComponent } from "./utils/work";
import BibiMockup from "../assets/works/bibi.webp";
import WayMockup from "../assets/works/way.webp";
import ParfumeoMockup from "../assets/works/parfumeo.webp";
import PlantzMockup from "../assets/works/plantz.webp";
import RestoMockup from "../assets/works/resto.webp";
import Photoshop from "../assets/skills/ps.svg";
import Flutter from "../assets/skills/flutter.svg";
import Mongo from "../assets/skills/MDB.svg";
import Firebase from "../assets/skills/firebase.svg";

import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFigma,
    faNodeJs,
    faReact,
    faPython,
} from "@fortawesome/free-brands-svg-icons";
import { forwardRef } from "react";

const Work = forwardRef((props, ref) => {
    return (
        <div className="work" ref={ref}>
            <div className="section">
                <div className="work-title">
                    <Fade>
                        <Title title="Nos créations" />
                    </Fade>
                </div>
                <Fade left>
                    <WorkComponent
                        img={BibiMockup}
                        alt="Mockup du site bibisorties.com"
                        title="bibi sorties"
                        tags={[
                            "UI/UI",
                            "Développement front",
                            "Développement back",
                        ]}
                        description={
                            <p>
                                Design, développement et mise en ligne du site
                                de <span className="bold">bibi</span>.<br />
                                <span className="bold">bibi</span> est un agenda
                                culturel couplé d’un média qui répertorie les
                                sorties en France.
                            </p>
                        }
                        icons={[
                            <FontAwesomeIcon icon={faFigma} title="Figma" />,
                            <img
                                src={Flutter}
                                alt="Icône Flutter"
                                title="Flutter"
                            />,
                            <FontAwesomeIcon icon={faReact} title="React" />,
                            <FontAwesomeIcon icon={faNodeJs} title="NodeJs" />,
                            <FontAwesomeIcon icon={faPython} title="Python" />,
                            <img
                                src={Mongo}
                                alt="Icône MongoDB"
                                title="MongoDB"
                            />,
                        ]}
                    />
                </Fade>
                <Fade right>
                    <WorkTwoComponent
                        img={WayMockup}
                        alt="Mockup du site wayorientation.fr"
                        title="WAY"
                        tags={["Développement front"]}
                        description={
                            <p>
                                Développement front-end du site vitrine de{" "}
                                <span className="bold">Way</span>.{" "}
                                <span className="bold">
                                    <br />
                                    Way
                                </span>{" "}
                                est un média d’orientation professionelle pour
                                toutes les personnes qui cherchent leur voie.
                            </p>
                        }
                        icons={[
                            <FontAwesomeIcon icon={faReact} title="React" />,
                        ]}
                        review={
                            <p>
                                “Ophélie a fait un travail remarquable pour
                                reprendre notre site et lui donner une nouvelle
                                vie. Elle a su matérialiser nos idées
                                d'évolutions, son sens de l'esthétisme est
                                vraiment un grand plus. Je vous la recommande !”
                                -{" "}
                                <span className="bold">
                                    Jade Duraz, CEO de WAY.
                                </span>
                            </p>
                        }
                    />
                </Fade>
                <Fade left>
                    <WorkComponent
                        img={ParfumeoMockup}
                        alt="Mockup de l'application Parfumeo"
                        title="Parfumeo"
                        tags={[
                            "UX/UI",
                            "Développement front",
                            "Développement back",
                            "IoT",
                        ]}
                        description={
                            <p>
                                Design et développement de l'application du
                                diffuseur de parfums connecté{" "}
                                <span className="bold">Parfumeo</span>.
                            </p>
                        }
                        icons={[
                            <FontAwesomeIcon icon={faFigma} title="Figma" />,
                            <img
                                src={Flutter}
                                alt="Icône Flutter"
                                title="Flutter"
                            />,
                            <img
                                src={Firebase}
                                alt="Icône Firebase"
                                title="Firebase"
                            />,
                        ]}
                        review={
                            <p>
                                “Ophélie à fait un excellent travail, elle a
                                réussi à embellir le projet parfumeo, grâce à
                                une application mobile pour diffuseur de parfum
                                connecté en bluetooh, l'application à était
                                particulièrement bien designé et pensé de A à Z,
                                autant sur la partie esthétique et ergonomique
                                avec Figma, que fonctionnel avec un codage
                                pointilleux sur Flutter. Sa capacité à être à
                                lécoute, empathique, force de proposition et
                                extravertie font que je la recommande pour vos
                                projets de développement d'application full
                                stack en loT !” -{" "}
                                <span className="bold">
                                    Enzo Didi, CEO de Parfumeo.
                                </span>
                            </p>
                        }
                    />
                </Fade>
                <Fade right>
                    <WorkTwoComponent
                        img={RestoMockup}
                        alt="Mockup du site fictif d'un restaurant parisien"
                        title="Restaurant"
                        tags={["UI/UX"]}
                        description={
                            <p>
                                Design d'un site web fictif pour un restaurant.
                            </p>
                        }
                        icons={[
                            <FontAwesomeIcon icon={faFigma} title="Figma" />,
                            <img
                                src={Photoshop}
                                alt="Icône Photoshop"
                                title="Photoshop"
                            />,
                        ]}
                    />
                </Fade>
                <Fade left>
                    <WorkComponent
                        img={PlantzMockup}
                        alt="Mockup du site fictif Plantz.com"
                        title="Plantz"
                        tags={["UI/UX"]}
                        description={
                            <p>
                                Design d’une maquette d’un site e-commerce
                                fictif de plantes.
                            </p>
                        }
                        icons={[
                            <FontAwesomeIcon icon={faFigma} title="Figma" />,
                            <img
                                src={Photoshop}
                                alt="Icône Photoshop"
                                title="Photoshop"
                            />,
                        ]}
                    />
                </Fade>
            </div>
        </div>
    );
});

export default Work;
