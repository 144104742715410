import React from "react"

export class Title extends React.Component {
    render() {
        return (
            <div className="title">
                <h3>{this.props.title}</h3>
                <div className="highlight">
                    <div className="highlight-gradient"></div>
                </div>
            </div>
        )
    }
}